import { Badge, Descriptions,Row,Col, Tooltip } from 'antd';
import React from 'react'
import { Typography,Button,Space } from 'antd';
const { Title } = Typography;
import KitTable from '../../components/kitTable';
import { PoweroffOutlined, EyeOutlined,EditOutlined } from '@ant-design/icons';
import ParcelStatus from '../../components/parcelStatus';
const ParcelShow = (props) => {
return (
<>
<Row>
    <Col span={20} style={{marginTop:20}}>
    <Descriptions title="Parcel Details" layout="vertical" bordered>
        <Descriptions.Item label="Parcel Number">{props.parcel.number}</Descriptions.Item>
        <Descriptions.Item label="Client">{props.client ? props.client.name : <Badge status="error" text={"N/A"} />}</Descriptions.Item>
        <Descriptions.Item label="System">{props.client ? props.client.domain :  <Badge status="error" text={"N/A"} />}</Descriptions.Item>
        <Descriptions.Item label="Status">
          {<ParcelStatus active={props.parcel.active} used={props.parcel.used} shipment={props.shipment} />}
        </Descriptions.Item>
        <Descriptions.Item label="Parcel Active">
          <Tooltip title={"True reflects an active parcel and false reflects an inactive parcel"} placement="bottom"><Badge status="success" text={props.parcel.active ? props.parcel.active.toString() : "false"} /></Tooltip>
        </Descriptions.Item>
        {(props.kit ? <Descriptions.Item label="Kit Size">{props.kit.length}</Descriptions.Item>:  <Descriptions.Item label="Kit">Unknown</Descriptions.Item> )}
        <Descriptions.Item label="Parcel Type">{props.parcel.details.parcel_type ? props.parcel.details.parcel_type :  <Badge status="error" text={"N/A"} />}</Descriptions.Item>
        <Descriptions.Item span={1} label="Actions">
            <Space size='middle'>
            {/* {props.parcel.client_id && props.shipment === null ? <Button icon={<EditOutlined />} type="primary" href={`/admin/parcels/${props.parcel.id}/edit`}>Edit Parcel Details</Button> : null} */}
            {props.shipment ? <Button icon={<EyeOutlined />} type="primary" href={`/shipments/${props.shipment.id}`}>View Parcel Shipment</Button> : <Button disabled icon={<EyeOutlined />} type="primary" href={`/admin/parcels/${props.parcel.id}`}>View Parcel Shipment</Button>}
            </Space>
        </Descriptions.Item>
        <Descriptions.Item label="Created at">{props.parcel.created_at}</Descriptions.Item>
        {(props.parcel.generic ? <></>:
        <>
          <Descriptions.Item label="Owner">{props.parcel.owner}</Descriptions.Item>
          <Descriptions.Item label="Name">{props.owner.name}</Descriptions.Item>
          {(props.parcel.owner === 'vessel' ? <Descriptions.Item label="Vessel ID">{props.owner.imo}</Descriptions.Item> :  <Descriptions.Item label="Dealer ID">{props.owner.dealer_id}</Descriptions.Item> )}
        </>
        )}
       
    </Descriptions>
    </Col>
  </Row>
  {(props.kit ?  <Row>
    <Col span={24}>
        <Title level={5}>Kit Details</Title>
        <KitTable kit={props.kit} />
        <Button icon={<EditOutlined />} type="primary" href={`/admin/kit/edit?kit_key=${props.parcel.kit_key}`}>Edit Kit Details</Button>
    </Col>
  </Row>:null )}
 
  </>
);
}
export default ParcelShow;