import { InboxOutlined,UploadOutlined } from '@ant-design/icons';
import { Col, message, Upload,Row,Form,Input,Button,Select,Switch } from 'antd';
import Title from 'antd/es/typography/Title';
import React, {useEffect,useState} from 'react'
const { Dragger } = Upload;
const NewCommodity = (props) => {
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = React.useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [showDest, setShowDest] = useState(true)
  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        },
      );
    },[showDest])
  const onFinish = (values) => {
    var token = document.getElementsByName("csrf-token")[0].content;
    const formData = new FormData()
    fileList.forEach((file) => {
      formData.append('files[]', file);
    });
    formData.append('name', values.name) 
    formData.append('display_name', values.display_name)
    formData.append('code', values.code)
    formData.append('description', values.description)
    formData.append('client_id', values.client_id)
    formData.append('active', values.active)
    formData.append('commodity_routing_active', values.commodity_routing_active)
    formData.append('destination_id', values.destination_id)

    fetch(`/admin/commodities`, {
      method: "POST",
      headers: {
        "X-CSRF-Token": token
      },
      credentials: "same-origin",
      body:formData
  })
  .then(res => {
    window.location.assign(res.url)
  })
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const uploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };
  return (
    <>
    <Row>
      <Col span={21}><Title level={3}>Create Commodity</Title></Col>
    </Row>
    <Row>
      <Col push={6} span={8}>  
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        >    
          <Form.Item  rules={[ { required: true }, ]}  name="name"  label="Commodity Name">
              <Input placeholder="..."/>
          </Form.Item>
          <Form.Item rules={[ { required: true, max: 72 }, ]}  name="display_name"  label="Commodity Name (To Be Displayed on Paperwork)">
              <Input placeholder="..."/>
          </Form.Item>
          <Form.Item  name="code"  label="Commodity Code" >
              <Input placeholder="12345678..."/>
          </Form.Item>
          <Form.Item  rules={[ { required: true, max:72 }, ]} name="description"  label="Commodity Description">
              <Input placeholder="..."/>
          </Form.Item>
          <Form.Item name="client_id" label="Client">
                 <Select
                  defaultValue="Please select Client"
                  options={props.clients}
                />
              </Form.Item>
              <Form.Item valuePropName="checked" name='active' label="Commodity Active">
            <Switch checkedChildren={"Active"} unCheckedChildren={"Inactive"} />
        </Form.Item>
        <Form.Item valuePropName="checked" name='commodity_routing_active' label="Commodity Routing Active">
            <Switch onChange={(e) => {setShowDest(!e)}} checkedChildren={"Active"} unCheckedChildren={"Inactive"} />
        </Form.Item>
        <Form.Item name="destination_id" label="Destination">
                 <Select
                 disabled={showDest}
                  defaultValue="Please select Destination"
                  options={props.destinations}
                />
              </Form.Item>
          <Form.Item name='files' label="Commodity Files (MSDS etc...)">
          <Upload {...uploadProps} multiple >
            <Button icon={<UploadOutlined />}>Select File(s)</Button>
            </Upload>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16, }} >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
          </Form.Item>
      </Form>
      </Col>
    </Row>
    </>

  );
 }
export default NewCommodity;