import React, { useEffect, useState } from 'react'
import { Button, Col, Row,Form,Input,Select, Checkbox,Radio, Divider,Spin,Descriptions,Alert,Switch} from 'antd';
import { PlusOutlined,MinusCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import KitTable from '../../components/kitTable';
const items = [
  {
    label: 'SL Fuel Kits',
    options:[
      {
        label: '22 Bottle Fuel Kit',
        value: 'fuel_22',
      },
      {
        label: '1 Bottle Carton',
        value: 'fuel_1',
      },
    ],
  },
  {
    label: 'Lubricant Kits',
    options: [
      {
        label: '5 Bottle Kit (Volvo)',
        value: 'lub_5_volvo',
      },
      {
        label: '10 Bottle Kit (Volvo)',
        value: 'lub_10_volvo',
      },
      {
        label: '20 Bottle Kit (Volvo)',
        value: 'lub_20_volvo',
      },
      {
        label: 'Flame Kit',
        value: 'lub_flame',
      },
      {
        label: 'Boskalis Kit',
        value: 'lub_boskalis',
      },
      {
        label: 'Chevron Kit',
        value: 'lub_chevron',
      },
    ],
  },
  { 
    label: 'Water Kits',
    options: [
      {
        label: '1 Bottle Kit',
        value: 'water',
      },
      {
        label: '2 Bottle Kit',
        value: 'water_2',
      },
    ],
  },
]
const { Title } = Typography;
const BulkActivateParcel = (props) => {
  const [nofitications, setNotifications] = useState(false)
  const [value, setValue] = useState(1);
  const [load, setLoad] = useState(false)
  const [parcels, setParcels] = useState()
  const [kitSize, setKitSize] = useState()
  const [err, setErr] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const [parcelStartId, setParcelStartId] = useState()
  const [parcelEndId, setParcelEndId] = useState()
  const [form] = Form.useForm();
  const startRange = Form.useWatch('start_range', form);
  const endRange = Form.useWatch('end_range', form);
  const [kitType, setKitType] = useState("")
  const generic = Form.useWatch('generic', form);
  const use = Form.useWatch('use', form);

  const fetchRange = (startRange,endRange) => {
    setErr(false)
    if (startRange.length === 8 && endRange.length === 8 ){
      var token = document.getElementsByName("csrf-token")[0].content;
    fetch(`/admin/bulk_parcel_query?start_range=${startRange}&end_range=${endRange}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      credentials: "same-origin"
  }).then(res => {
    return res.json()
  }).then(parcels => {
    console.log(parcels)
    if (parcels.status === 'ok'){
      setKitSize(parcels.kit_size)
      setParcels(parcels.parcels)
      setParcelStartId(parcels.start_id)
      setParcelEndId(parcels.end_id)
    } else {
      setErrMessage(parcels)
      setErr(true)
    }
  }).catch(err => {
    console.log(err)
  })
    } else {
      null
    }
  }
  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  function submitParcel(values){
    setLoad(true)
    var token = document.getElementsByName("csrf-token")[0].content;
    fetch("/admin/parcels.json", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      credentials: "same-origin",
      body:JSON.stringify({kit_size: kitSize, kit_quantity: kitQuantity, details: kitType, values:values})
      })
     .then(res => {
        return res.json()
      }).then(resp => {
        setParcelRes(resp)
        setParcelsGenerated(true)
        setLoad(false)
        console.log("JSON IN")
      }) 
    }
  useEffect(() => {
    if (startRange && endRange){
      fetchRange(startRange,endRange)
    }
    },[startRange,endRange])
  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };
  const onFinish = (values) => {
    var token = document.getElementsByName("csrf-token")[0].content;
    values.start_id = parcelStartId
    values.end_id = parcelEndId
    fetch("/admin/activate_parcels", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      credentials: "same-origin",
      body:JSON.stringify(values)
  })
  .then(res => {
    window.location.assign(res.url)
  })
  };
  const handleKitTypeChange = (value) => {
    setKitType(value)
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const formItemLayout = { labelCol: { xs: { span: 24, }, sm: { span: 4, }, }, wrapperCol: { xs: { span: 24, }, sm: { span: 20, }, }, };
  const formItemLayoutWithOutLabel = { wrapperCol: { xs: { span: 24, offset: 0, }, sm: { span: 20, offset: 4, }, }, };

  return (
  <>
   <Row>
    <Col span={21}>
      <Title level={3}>Activate Parcels</Title>
    </Col>
    <Col span={3}>
    </Col>
    </Row>
  <Row>
    {load ? <Spin tip="Loading" size="small"></Spin> : null} 
    <Col push={4} span={16}>
              <Form form={form} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} style={{ maxWidth: 600 }} initialValues={null} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off"
              >
              <Form.Item name={"start_range"} label="Start Range">
                <Input placeholder="..."/>
              </Form.Item>
              <Form.Item name="end_range"  label="End Range">
                <Input placeholder="..."/>
              </Form.Item>
              {err ? <Form.Item validateStatus='error' label="Error"><Alert message={errMessage.message} type="error" /></Form.Item>:null}
             
             
             
                <>
                <Form.Item required name="client" label="Client">
                   <Select
                    defaultValue="Please select Client"
                    options={props.clients}
                  />
                </Form.Item>
                 
              <Form.Item label="Kit Type">
                <Select
                  defaultValue="Please select Kit Type"
                  onChange={handleKitTypeChange}
                  options={items}
                />
              
              </Form.Item>
                 <Form.Item name='use' label="Kit will be used for">
                  <Radio.Group><Radio value={'vessel'}>Vessel</Radio> <Radio value={'dealer'}>Dealer</Radio></Radio.Group>
                </Form.Item>
                <Form.Item name='generic' label="Kit Parcels Generic">
                  <Switch  checkedChildren={"Generic"} unCheckedChildren={"Not Generic"} />
               </Form.Item>
               {generic ? null
               :
               use === 'vessel' ?  <>
                 <Form.Item label="Vessel" name="vessel_id">
                  <Select
                    showSearch
                    defaultValue="Please select Vessel"
                    options={props.vessels}
                    filterOption={filterOption}
                    />
                </Form.Item>
               </> 
               :  
               <>
                <Form.Item label='Dealer Name' name='vessel_name'><Input placeholder='Dealer Name...'  /></Form.Item>
                </>
              
               }
               </>
        {nofitications ?
        
        <Form.Item>
        <Form.List name="notification_emails">
  {(fields, { add, remove }) => (
    <>
      {fields.map((field, index) => (
        <Form.Item
          {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
          label={index === 0 ? 'Email' : ''}
          required={false}
          key={field.key}
        >
          <Form.Item
            {...field}
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input email in this field.",
              },
            ]}
            noStyle
          >
            <Input
              placeholder="Email address..."
              style={{
                width: '60%',
              }}
            />
          </Form.Item>
          {fields.length > 1 ? (
            <MinusCircleOutlined
              className="dynamic-delete-button"
              onClick={() => remove(field.name)}
            />
          ) : null}
        </Form.Item>
      ))}
      <Form.Item>
        <Button
          type="dashed"
          onClick={() => add()}
         
          icon={<PlusOutlined />}
        >
          Add field
        </Button>
      </Form.Item>
    </>
  )}
</Form.List>
</Form.Item>
        
        : null}
           
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
      </Col>
      <Col>
     
      </Col>
  </Row>
  <Row>
   <Divider />
    <Col push={1} span={16}>
    {parcels && !err ? 
      <Descriptions title="Kit Overview" layout="vertical" bordered>
      <Descriptions.Item label="Kit Size">{kitSize}</Descriptions.Item>
          <Descriptions.Item span={2} label="Total Number of Parcels">{parcels.length}</Descriptions.Item>
          <Descriptions.Item label="Kit Details"> </Descriptions.Item>
      </Descriptions>
     : null}
    {/* <Title level={3}>Kit Overview</Title>
   
        <KitTable kit={props.kit.parcels} /> */}
      </Col>
  </Row>
  </>
  )
};
export default BulkActivateParcel;