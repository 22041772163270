import React, { useEffect,useState } from 'react'
import { Button, Col, Divider, Row,Select } from 'antd';
import { PlusOutlined,PoweroffOutlined,ReloadOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import Notification from '../../components/notification';
import VesselsTable from '../../components/vesselsTable';
import Search from "antd/es/input/Search";

const { Title } = Typography;
const VesselsIndex = (props) => {
  const [success, setSuccess] = useState(false)
  const [owner, setOwner] = useState('vessel')
  const [load, setLoad] = useState(false)
  const [vessels, setVessels] = useState(props.vessels)
  const onSearch = (value) => {
    setLoad(true)
      fetch(`/search_vessel?${owner}=${value}`).then(res => {
       return res.json()}).then(vessels => {setVessels(vessels); setLoad(false)})
  }
  useEffect(() => {
   
  },[vessels])
  return (
  <>
   <Row>
    <Col span={18}>
      <Title level={3}>Vessels Overview</Title>
    </Col>
    <Col push={2} span={3}>
      <Button icon={<PlusOutlined />} style={{marginTop: 20}} href='/admin/vessels/new' type="primary"level={3}>Create Vessel</Button>
    </Col>
    </Row>
    <Divider />
  <Row style={{marginBottom: 10}}>
  <Col push={4} span={6}>
    <Button onClick={() => {setVessels(props.vessels)}} icon={<ReloadOutlined />}></Button>
    </Col>
    <Col push={10} span={6}>
      <Search addonBefore={<Select value={owner} onChange={setOwner} defaultValue={{label: 'Vessel Name', value:'vessel'}} options={[{label: 'Vessel Name', value: 'vessel'}, {label: 'Vessel IMO', value: "imo"}]} />} allowClear onSearch={(value) => {(value.length === 0 ? setVessels(props.vessels) : onSearch(value))}} placeholder={'Search Vessel...'} />
    </Col>
  </Row>
  <Row>
    <Col push={4} span={18}>
      <VesselsTable data={vessels} />
      </Col>
  </Row>
  <Row>
    <Col span={4} push={4}>
     {/* {success ? <Notification message={"Client Successfully Created"} messageType='success' icon='success' />:null} */}
    </Col>
  </Row>
  </>
  )
};
export default VesselsIndex;