import { InboxOutlined,UploadOutlined } from '@ant-design/icons';
import { Col, message, Upload,Row,Form,Input,Button,Select,Switch,Transfer } from 'antd';
import Title from 'antd/es/typography/Title';
import React, {useEffect,useState} from 'react'
import {PostalCodeRequired} from '../../utils/shared'
const { Dragger } = Upload;
const EditZone = (props) => {
  const [countries, setCountries] = useState(props.countries)
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = React.useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false); 
  const [postCodeReq, setPostCodeReq] = useState(true)
  const [targetKeys, setTargetKeys] = useState(props.zone.countries);
  const [selectedKeys, setSelectedKeys] = useState([]);
  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        },
      );
    },[])

  const returnAddress = (values) => {
    var address = values
    delete address["name"]
    delete address["client_id"]
    var country = values.country
    var countryDetails = {}
    props.countries.forEach(c => {
        console.log(country === c.value)
        if (c.value === country){
            countryDetails = {name: c.details.name, code: c.details.code, region: c.details.region}
        }
    })
    address['country_details'] = countryDetails
    return address
  }
  const onFinish = (values) => {
    var token = document.getElementsByName("csrf-token")[0].content;
    fetch(`/admin/zones/${props.zone.id}.json`, {
      method: "PUT",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      },
      credentials: "same-origin",
      body:JSON.stringify(values)
  })
  .then(res => {
    window.location.assign(res.url)
  })
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const onChange = (nextTargetKeys, direction, moveKeys) => {
    console.log('targetKeys:', nextTargetKeys);
    console.log('direction:', direction);
    console.log('moveKeys:', moveKeys);
    setTargetKeys(nextTargetKeys);
  };
  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    console.log('sourceSelectedKeys:', sourceSelectedKeys);
    console.log('targetSelectedKeys:', targetSelectedKeys);
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };
  const onScroll = (direction, e) => {
    console.log('direction:', direction);
    console.log('target:', e.target);
  };
  const handleSearch = (dir, value) => {
    console.log('search:', dir, value);
  };
  console.log(props)
  return (
    <>
    <Row>
      <Col span={21}><Title level={3}>Edit Zone {props.zone.name}</Title></Col>
    </Row>
    <Row>
      <Col push={6} span={8}>  
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={props.zone}
        > 
        <Form.Item name='countries' label='Zones'>
         <Transfer
            showSearch            
            dataSource={countries}
            titles={['All Countries', 'Active Countries']}
            onChange={onChange}
            onSelectChange={onSelectChange}
            onScroll={onScroll}
            onSearch={handleSearch}
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            render={(item) => item.label}
            />   
            </Form.Item>
          <Form.Item  rules={[ { required: true }, ]}  name="name"  label="Zone Name">
              <Input placeholder="..."/>
          </Form.Item>
          <Form.Item name="client_id" label="Client">
                 <Select
                  defaultValue="Please select Client"
                  options={props.clients}
                />
              </Form.Item>
              <Form.Item name="destination_id" label="Destination">
                 <Select
                  defaultValue="Please select Destination"
                  options={props.destinations}
                />
              </Form.Item>
         
        <Form.Item wrapperCol={{ offset: 8, span: 16, }} >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
          </Form.Item>
      </Form>
      </Col>
    </Row>
    </>

  );
 }
export default EditZone;