import { Badge, Descriptions,Row,Col, Tooltip,Tag } from 'antd';
import React,{useEffect, useState} from 'react'
import { Typography,Button,Space,Select,Modal,Form,Alert,notification } from 'antd';
const { Title } = Typography;
import { PoweroffOutlined, EyeOutlined,EditOutlined,CloudDownloadOutlined , ReloadOutlined, CalendarOutlined,CloseOutlined} from '@ant-design/icons';
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import BookCollectionModal from '../../components/bookCollectionModal.js'
import CancelCollectionModal from '../../components/cancelCollectionModal.js';
import moment from 'moment';

const ReturnBadgeStatus = (status) => {
    switch(status){
      case 'Fail':
        return "error"
        break;
      case 'Awaiting Pickup':
        return "processing"
        break;
      case 'In Transit':
        return 'success'
      case 'Exception':
        return 'warning'
      default:
        return "default"
    }
  }

const ReturnCollectionColour = (ref) => {
  switch(ref){
    case 'A collection was not requested with your shipment.':
      return "default"
      break;
      break;
    case 'In A collection could not be scheduled for your selected times, please rearrange your shipment collection.':
      return 'error'
    default:
      return "success"
  }
}

const ShowShipment = (props) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [shipmentStatus, setShipmentStatus] = useState(props.shipment.status)
  const [collectionModal, setCollectionModal] = useState(false)
  const [cancelCollectionModal, setCancelCollectionModal] = useState(false)
  const [newCollection, setNewCollection] = useState()
  const [shipment, setShipment] = useState(props.shipment)
  const showModal = () => {
    setOpen(true);
  };
  const [api, contextHolder] = notification.useNotification();
  const collectionNotification = (type, title, description) => {
    api[type]({
      message: title,
      description:
        description,
    });
  };

  const updateShipmentTracking = () => {
    var token = document.getElementsByName("csrf-token")[0].content;
    setConfirmLoading(true);
      setOpen(false);
      setConfirmLoading(false);
      fetch(`/shipment_tracking?shipment_id=${shipment.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token
        },
        credentials: "same-origin",
    })
    .then(res => {
      window.location.reload()
    })
  }
  
  const handleOk = () => {
    var token = document.getElementsByName("csrf-token")[0].content;
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
      fetch(`/shipments/${shipment.id}.json`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token
        },
        credentials: "same-origin",
        body:JSON.stringify({status: shipmentStatus})
    })
    .then(res => {
      window.location.reload()
    })
    }, 1500);
  };
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };
  console.log(typeof shipment.pieces.collection_cancellation != undefined)
return (
<>
<Row>
  <Col span={1}/>
    <Col span={22} style={{marginTop:20}}>
      <Row justify={'space-between'}>
        <Button type='primary' href={'/admin/dashboard'} style={{margin:10}}>Back</Button>
        <Title level={4}>Shipment {shipment.id}</Title>
        <>
        <Button type="primary" onClick={showModal}>
          Edit Shipment
        </Button>
        <Modal
          title={`Edit Shipment ${shipment.id}`}
          open={open}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
        >
          <Form.Item label='Shipment Status' style={{marginTop:20}}>
        <Select
          defaultValue={shipment.status}
          style={{ width: 120, }}
          onChange={setShipmentStatus}
          options={[
            {
              value: 'Awaiting Pickup',
              label: 'Awaiting Pickup',
            },
            {
              value: 'In Transit',
              label: 'In Transit',
            },
            {
              value: 'Exception',
              label: 'Exception',
            },
            {
              value: 'Delivered',
              label: 'Delivered',
            },
            {
              value: 'Cancelled',
              label: 'Cancelled',
            },
            {
              value: 'Closed',
              label: 'Closed',
            }
          ]}
        />
        </Form.Item>
        </Modal>
      </>
        </Row>
    <CancelCollectionModal collectionNotification={collectionNotification} setCancelCollectionModal={setCancelCollectionModal} open={cancelCollectionModal} shipment={shipment} setShipment={setShipment} />
    <BookCollectionModal collectionNotification={collectionNotification} setShipment={setShipment} setCollectionModal={setCollectionModal} open={collectionModal} shipment={shipment} collection={newCollection} />
    {contextHolder}
    <Descriptions title={`Shipment ${shipment.id} Details`} layout="vertical" bordered>
        <Descriptions.Item label={<Space direction='row'><Typography>AWB Number</Typography> / <Tag color={ReturnCollectionColour(shipment.pieces.collection_reference)}>Collection Reference</Tag></Space>}>
          <Space direction='horizontal' wrap align='end' split>
            <Typography>{shipment.awb_number}</Typography>
              <Tag color={ReturnCollectionColour(shipment.pieces.collection_reference)}>{shipment.pieces.collection_reference}</Tag>
              {(props.collection ?  <Button onClick={() => {setCollectionModal(true); setNewCollection(false)}} size='small' icon={<CalendarOutlined />} type='primary'>Amend Collection</Button>: <Button onClick={() => {setCollectionModal(true); setNewCollection(false)}} size='small' icon={<CalendarOutlined />} type='primary'>Schedule Collection</Button>)}
              {(props.collection_cancellable ?  <Button danger onClick={() => setCancelCollectionModal(true)} size='small' icon={<CloseOutlined />} type='primary'>Cancel Collection</Button>: null)}
            </Space>
        </Descriptions.Item>
        <Descriptions.Item label="Parcel Number(s)">
            {props.parcel.map(p => {return <a href={`/admin/parcels/${p.id}`}><Tag color="#498CA4">{p.number}</Tag></a>})}
        </Descriptions.Item>
        <Descriptions.Item label='Status'>
            <Badge status={ReturnBadgeStatus(shipment.status)} text={shipment.status} />
        </Descriptions.Item>
        <Descriptions.Item label="Client">
            <Typography>{props.client.name}</Typography>
        </Descriptions.Item>
        <Descriptions.Item label="System">
            <Typography>{props.client.domain}</Typography>
        </Descriptions.Item>
        <Descriptions.Item label="Parcel Type">
            <Typography>{props.parcel[0].details.parcel_type}</Typography>
        </Descriptions.Item>
       
        <Descriptions.Item label="Origin">
            <>
                Company Name: {shipment.origin.company_name}
                <br />
                Contact Name: {shipment.origin.contact_name}
                <br />
                Contact Email: {shipment.origin.contact_email}
                <br />
                Contact Phone: {shipment.origin.contact_phone}
                <br />
                Street Lines: {shipment.origin.address_line_one}
                <br />
                City: {shipment.origin.city}
                <br />
                Postal/Zip Code: {shipment.origin.postcode}
                <br />
                Country: {getUnicodeFlagIcon(shipment.origin.country_details.code)} {shipment.origin.country_details.name}


            </>
        </Descriptions.Item>
        <Descriptions.Item label="Destination" span={2}>
            <>
                Company Name: {shipment.destination.company_name}
                <br />
                Contact Name: {shipment.destination.contact_name}
                <br />
                Contact Email: {shipment.destination.contact_email}
                <br />
                Contact Phone: {shipment.destination.contact_phone}
                <br />
                Street Lines: {shipment.destination.address_line_one}
                <br />
                City: {shipment.destination.city}
                <br />
                Postal/Zip Code: {shipment.destination.postcode}
                <br />
                Country: {getUnicodeFlagIcon(shipment.destination.country_details.code)} {shipment.destination.country_details.name}


            </>
        </Descriptions.Item>
        <Descriptions.Item label="Collection Details" span={3}>
            <>
                Requested Collection Date: {moment(shipment.pieces.collection_date).format("dddd Do MMMM YYYY")}
                <br />
                Requested Collection Time Slot: {shipment.pieces.collection_earliest} - {shipment.pieces.collection_latest}
                <br />           
            </>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Documents">
            <Button style={{margin:5}} type='primary' icon={<CloudDownloadOutlined />} href={props.documents.awb_document}>AWB</Button><Button type='primary' style={{margin:5}} icon={<CloudDownloadOutlined />} href={props.documents.ci_document}>Commercial Invoice</Button>
        </Descriptions.Item>
        <Descriptions.Item label={<Space><p>Tracking Events</p><Button onClick={() => {updateShipmentTracking()}} style={{marginLeft:10}} icon={<ReloadOutlined />}></Button></Space>}>
            <>
              
              {shipment.tracking && Object.keys(shipment.tracking) > 0 ?
              shipment.tracking.tracking.tracking_events.map(event => {
                if (Object.keys(event).length > 0){
                  return (<p>• {event.description} - on {event.date} at {event.time}</p> )
                }
              }) 
               : null}


            </>
        </Descriptions.Item>
    </Descriptions>
    </Col>
  </Row>
  <Row>
    {/* <Col span={24} style={{marginTop:30}}>
        <Button icon={<EditOutlined />} type="primary" href={`/admin/destinations/${props.destination.id}/edit`}>Edit Destination Details</Button>
    </Col> */}
  </Row>
  </>
);
}
export default ShowShipment;