import { Badge, Descriptions,Row,Col,Form,Input,Select, Tag,Checkbox,Radio } from 'antd';
import { Typography,Button,Space,Switch } from 'antd';
import KitTable from '../../components/kitTable';
import { PoweroffOutlined, EyeOutlined,EditOutlined } from '@ant-design/icons';
import React, {useState} from 'react'

const { Title } = Typography;

const KitEdit = (props) => {
    const [form] = Form.useForm();
  const generic = Form.useWatch('generic', form);
  const use = Form.useWatch('use', form);
    const onFinish = (values) => {
        var token = document.getElementsByName("csrf-token")[0].content;
        fetch("/admin/update_kit", {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": token
            },
            credentials: "same-origin",
            body:JSON.stringify(values)
        })
        .then(res => {
          console.log(res)
          window.location.assign('/admin/parcels')
        })
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      const formItemLayout = {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 4,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 20,
          },
        },
      };
      const formItemLayoutWithOutLabel = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 20,
            offset: 4,
          },
        },
      };
return (
<>
<Row>
    <Title level={3}>Edit Kit  {props.kit[0].kit_key}</Title>
</Row>
<Row>
    <Col push={4} span={20}  style={{marginTop:20}}>
                <Form
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={props.inital_form_values}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
              <Form.Item disabled name={"start_range"} label="Start Range">
                <Input disabled placeholder="..."/>
              </Form.Item>
              <Form.Item disabled  name="end_range"  label="End Range">
                <Input disabled placeholder="..."/>
              </Form.Item>
              <Form.Item hidden name="start_id"  label="Total Number of Parcels">
                <Input disabled placeholder="..."/>
              </Form.Item>
              <Form.Item hidden name="end_id"  label="Total Number of Parcels">
                <Input disabled placeholder="..."/>
              </Form.Item>
              <Form.Item name="client" label="Client">
                 <Select
                  defaultValue="Please select Client"
                  options={props.clients}
                />
              </Form.Item>
              <Form.Item valuePropName='checked' name='active' label="Kit Parcels Active">
            <Switch  checkedChildren={"Active"} unCheckedChildren={"Inactive"} />
        </Form.Item>
        <Form.Item  valuePropName='checked' name='generic' label="Kit Parcels Generic">
            <Switch  checkedChildren={"Generic"} unCheckedChildren={"Not Generic"} />
        </Form.Item>
        <Form.Item name='use' label="Kit will be used for" rules={[ { required: true, message: 'Please choose who will be using this kit' }, ]}>
                <Radio.Group ><Radio  value={'vessel'}>Vessel</Radio> <Radio value={'dealer'}>Dealer</Radio></Radio.Group>
              </Form.Item>
              
              {generic ? null
             :
             use === 'vessel' ?  <>
               <Form.Item label="Vessel" name="owner_id">
                <Select
                  defaultValue="Please select Vessel"
                  options={props.vessels}
                />
              </Form.Item>
             </> 
             :  
             <>
              <Form.Item label='Dealer Name' rules={[ { required: true, message: 'Dealer Name Required' }, ]} name='dealer_name'><Input placeholder='Dealer Name...'  /></Form.Item>
              <Form.Item label='Dealer ID' rules={[ { required: true, message: 'Dealer ID Required' }, ]}  name='dealer_id'><Input placeholder='Dealer Name...'  /></Form.Item>
              </>
            
             }

              <Form.Item label="Kit Type" name="parcel_type">
                <Select
                  defaultValue="Please select Kit Type"
                  options={[
                    { value: 'fuel', label: 'Fuel Kit' },
                    { value: 'lubricant', label: 'Lube Kit' },
                    { value: 'water', label: 'Water Kit' },
                  ]}
                />
              </Form.Item>
              <Form.Item  name="kit_size"  label="Kit Size">
                <Input disabled placeholder="..."/>
              </Form.Item>
              <Form.Item   name="number_of_parcels"  label="Total Number of Parcels">
                <Input disabled placeholder="..."/>
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
            </Col>
  </Row>
  <Row>
  <Col span={24}>
        <Title level={5}>Kit Details</Title>
        <KitTable kit={props.kit} />
    </Col>
    </Row>
  </>
);
}
export default KitEdit;