import React, { useEffect,useState } from 'react'
import { Button, Col, Divider, Row } from 'antd';
import { PlusOutlined,PoweroffOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import Notification from '../../components/notification';
import CommoditiesTable from '../../components/commoditiesTable';

const { Title } = Typography;
const CommodityIndex = (props) => {
    console.log(props.commodities)
  const [success, setSuccess] = useState(false)
  const [commodities, setCommodities] = useState()
  const [load, setLoad]=useState()
  const fetchCommodities = () => {
    fetch(`/admin/commodities.json`).then(res => res.json()).then(commodities => {console.log(commodities);setCommodities(commodities); setLoad(false)})
  }
  useEffect(() => {
   fetchCommodities()
  },[])
  return (
  <>
   <Row>
    <Col span={21}>
      <Title level={3}>Commodities Overview</Title>
    </Col>
    <Col span={3}>
      <Button icon={<PlusOutlined />} style={{marginTop: 20}} href='/admin/commodities/new' type="primary"level={3}>Create Commodity</Button>
    </Col>
    </Row>
    <Divider />
  <Row>
    <Col push={4} span={16}>
      <CommoditiesTable load={load} data={commodities} />
      </Col>
  </Row>
  <Row>
    <Col span={4} push={4}>
     {/* {success ? <Notification message={"Client Successfully Created"} messageType='success' icon='success' />:null} */}
    </Col>
  </Row>
  </>
  )
};
export default CommodityIndex;