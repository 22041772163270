import React, { useEffect, useState } from "react"
import { MailOutlined, SettingOutlined,AppstoreOutlined } from '@ant-design/icons';
import { Layout, Menu, Row, Typography,Col,Button, Input, Form,Select, Spin,Radio,Switch ,Table, Divider} from 'antd';
import AdminShipmentsTable from "../../components/adminShipmentsTable";
import { Dropdown, Space } from 'antd';
const { Title,Paragraph } = Typography;

const NewParcels = (props) => {
  const items = [
    {
      label: 'SL Fuel Kits',
      options:[
        {
          label: '22 Bottle Fuel Kit',
          value: 'fuel_22',
        },
        {
          label: '1 Bottle Carton',
          value: 'fuel_1',
        },
      ],
    },
    {
      label: 'Lubricant Kits',
      options: [
        {
          label: '5 Bottle Kit (Volvo)',
          value: 'lub_5_volvo',
        },
        {
          label: '10 Bottle Kit (Volvo)',
          value: 'lub_10_volvo',
        },
        {
          label: '20 Bottle Kit (Volvo)',
          value: 'lub_20_volvo',
        },
        {
          label: 'Flame Kit',
          value: 'lub_flame',
        },
        {
          label: 'Boskalis Kit',
          value: 'lub_boskalis',
        },
        {
          label: 'Chevron Kit',
          value: 'lub_chevron',
        },
      ],
    },
    { 
      label: 'Water Kits',
      options: [
        {
          label: '1 Bottle Kit',
          value: 'water',
        },
        {
          label: '2 Bottle Kit',
          value: 'water_2',
        },
      ],
    },
  ]
  
  const [parcels, setParcels] = useState([])
  const [parcelsGenerated, setParcelsGenerated] = useState(false)
  const [kitSize, setKitSize] = useState(0)
  const [kitQuantity, setKitQuantity] = useState(0)
  const [kitType, setKitType] = useState("")
  const [load, setLoad] = useState(false)
  const [parcelRes, setParcelRes] = useState()
  const [form] = Form.useForm();
  const generic = Form.useWatch('generic', form);
  const use = Form.useWatch('use', form);

  const handleKitTypeChange = (value) => {
    setKitType(value)
  };
  const handleKitQuantity = (e) => {
    setKitQuantity(e.target.value)
  }
  const handleKitSize = (e) => {
    setKitSize(e.target.value)
  }
  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  function submitParcel(values){
    setLoad(true)
    var token = document.getElementsByName("csrf-token")[0].content;
    fetch("/admin/parcels.json", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      credentials: "same-origin",
      body:JSON.stringify({kit_size: kitSize, kit_quantity: kitQuantity, details: kitType, values:values, include_qr: values.include_qr})
      })
     .then(res => {
        return res.json()
      }).then(resp => {
        setParcelRes(resp)
        setParcelsGenerated(true)
        setLoad(false)
        console.log("JSON IN")
      }) 
    }


  
    return (
     <div>
        <Title level={3}>Create Parcel Numbers</Title>
        <Divider />
      <Row>
        <Col span={4} />
        <Col span={6}>
            <Form form={form} onFinish={submitParcel}>
              <Form.Item onChange={handleKitQuantity} label="Kit Quantity">
                <Input type="number" placeholder="..."/>
              </Form.Item>
              <Form.Item  label="Kit Size">
                <Input onChange={handleKitSize} type="number" placeholder="..."/>
              </Form.Item>
              <Form.Item label="Kit Type">
                <Select
                  defaultValue="Please select Kit Type"
                  onChange={handleKitTypeChange}
                  options={items}
                />
              
              </Form.Item>
             
             
                <>
                <Form.Item required name="client" label="Client">
                   <Select
                    defaultValue="Please select Client"
                    options={props.clients}
                  />
                </Form.Item>
                 <Form.Item name='use' label="Kit will be used for">
                  <Radio.Group><Radio value={'vessel'}>Vessel</Radio> <Radio value={'dealer'}>Dealer</Radio></Radio.Group>
                </Form.Item>
                <Form.Item name='generic' label="Kit Parcels Generic">
                  <Switch  checkedChildren={"Generic"} unCheckedChildren={"Not Generic"} />
               </Form.Item>
               <Form.Item name='include_qr' label="Include Start/End Sticker">
                  <Switch  checkedChildren={"Include Start/End Sticker"} unCheckedChildren={"Do Not Include Start/End Sticker"} />
               </Form.Item>
               {generic ? null
               :
               use === 'vessel' ?  <>
                 <Form.Item label="Vessel" name="vessel_id">
                  <Select
                    showSearch
                    defaultValue="Please select Vessel"
                    options={props.vessels}
                    filterOption={filterOption}
                    />
                </Form.Item>
               </> 
               :  
               <>
                <Form.Item label='Dealer Name' name='vessel_name'><Input placeholder='Dealer Name...'  /></Form.Item>
                </>
            
               }
                {/* <Form.Item onClick={(e) => {setNotifications(e.target.checked)}} name="notifications" label="Shipment Email" valuePropName="checked">
                  <Checkbox />
                </Form.Item> */}
               
                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                <Form.Item><Button  htmlType="submit" type="primary" >Generate Parcels</Button></Form.Item>
                </Form.Item>
                </>
  
              
            </Form>
              {load ? <Spin tip="loading..." /> : null}
              { parcelsGenerated   ? <Form.Item><Button href={"/admin/sampleship/parcels-sheet/download_pdf"} target="_blank" rel="noreferrer noopener">Download Parcels PDF</Button></Form.Item>:null}
              {parcelsGenerated ? 
              <><Title level={4} >Parcels Generated</Title>
                <Table dataSource={parcelRes} columns={[{title: 'Parcel Number', dataIndex: 'number', key: 'number'}]}  /></>: null}
        </Col>
      </Row>
      </div>
     );
}

export default NewParcels
 