import { Badge, Descriptions,Row,Col,Form,Select, Tag,Input,Upload } from 'antd';
import React, {useState, useEffect} from 'react'
import { Typography,Button,Space,Switch } from 'antd';
const { Title } = Typography;
import KitTable from '../../components/kitTable';
import { PoweroffOutlined, EyeOutlined,UploadOutlined,CloudDownloadOutlined } from '@ant-design/icons';
const EditCommodity = (props) => {
    const [fileList, setFileList] = useState([]);
    const [showDest, setShowDest] = useState(props.commodity.commodity_routing_active)
    const onFinish = (values) => {
        var token = document.getElementsByName("csrf-token")[0].content;
        const formData = new FormData()
        fileList.forEach((file) => {
          formData.append('files[]', file);
        });
        formData.append('name', values.name) 
        formData.append('display_name', values.display_name)
        formData.append('code', values.code)
        formData.append('description', values.description)
        formData.append('client_id', values.client_id)
        formData.append('active', values.active)
        formData.append('commodity_routing_active', values.commodity_routing_active)
        formData.append('destination_id', values.destination_id)
    
        fetch(`/admin/commodities/${props.commodity.id}.json`, {
          method: "PUT",
          headers: {
            "X-CSRF-Token": token
          },
          credentials: "same-origin",
          body:formData
      })
      .then(res => {
        window.location.assign(res.url.slice(0, -5))
      })
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      const uploadProps = {
        onRemove: (file) => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          setFileList(newFileList);
        },
        beforeUpload: (file) => {
          setFileList([...fileList, file]);
          return false;
        },
        fileList,
      };
      const formItemLayout = {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 4,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 20,
          },
        },
      };
      const formItemLayoutWithOutLabel = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 20,
            offset: 4,
          },
        },
      };
return (
<>
<Row>
    <Title level={3}>Edit Parcel {props.commodity.name}</Title>
</Row>
<Row>
    <Col push={6} span={14} style={{marginTop:20}}>
    <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={props.commodity}
        >    
          <Form.Item  rules={[ { required: true }, ]}  name="name"  label="Commodity Name">
              <Input placeholder="..."/>
          </Form.Item>
          <Form.Item rules={[ { required: true, max: 72 }, ]}  name="display_name"  label="Commodity Name (To Be Displayed on Paperwork)">
              <Input placeholder="..."/>
          </Form.Item>
          <Form.Item  name="code"  label="Commodity Code" >
              <Input placeholder="12345678..."/>
          </Form.Item>
          <Form.Item  rules={[ { required: true, max:72 }, ]} name="description"  label="Commodity Description">
              <Input placeholder="..."/>
          </Form.Item>
          <Form.Item name="client_id" label="Client">
                 <Select
                  defaultValue="Please select Client"
                  options={props.clients}
                />
              </Form.Item>
              <Form.Item valuePropName="checked" name='commodity_routing_active' label="Commodity Routing Active">
            <Switch onChange={(e) => {setShowDest(!e)}} checkedChildren={"Active"} unCheckedChildren={"Inactive"} />
        </Form.Item>
        <Form.Item name="destination_id" label="Destination">
                 <Select
                 disabled={showDest}
                  defaultValue="Please select Destination"
                  options={props.destinations}
                />
              </Form.Item>
              <Form.Item valuePropName="checked" name='active' label="Commodity Active">
            <Switch checkedChildren={"Active"} unCheckedChildren={"Inactive"} />
        </Form.Item>
          <Form.Item name='files' label="Commodity Files (MSDS etc...)">
          <Upload {...uploadProps} multiple >
            <Button icon={<UploadOutlined />}>Select File(s)</Button>
            </Upload>
        </Form.Item>
        <Form.Item>
          {props.commodity_file_urls.map(url => {
            return <Button style={{marginLeft:5}} href={url.file} icon={<CloudDownloadOutlined />}>{url.name}</Button>
          })}
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16, }} >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
          </Form.Item>
      </Form>
        </Col>
        </Row>
    <Row>
    <Col span={20} style={{marginTop:30}}>
    </Col>
  </Row>
  <Row>
  </Row>
  </>
);
}
export default EditCommodity;