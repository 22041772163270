import React, { useEffect,useState } from 'react'
import { Button, Col, Divider, Row } from 'antd';
import { PlusOutlined,PoweroffOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import DestinationsTable from '../../components/destinationsTable';

const { Title } = Typography;
const DestinationIndex = (props) => {
  const [success, setSuccess] = useState(false)
  useEffect(() => {
   
  },[])
  return (
  <>
   <Row>
    <Col span={21}>
      <Title level={3}>Destinations Overview</Title>
    </Col>
    <Col span={3}>
      <Button icon={<PlusOutlined />} style={{marginTop: 20}} href='/admin/destinations/new' type="primary"level={3}>Create Destinaton</Button>
    </Col>
    </Row>
    <Divider />
  <Row>
    <Col push={4} span={16}>
      <DestinationsTable data={props.destinations} />
      </Col>
  </Row>
  <Row>
    <Col span={4} push={4}>
     {/* {success ? <Notification message={"Client Successfully Created"} messageType='success' icon='success' />:null} */}
    </Col>
  </Row>
  </>
  )
};
export default DestinationIndex;