import React, { useEffect } from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined,UserOutlined,DashboardOutlined,TeamOutlined,ShoppingCartOutlined,NumberOutlined,ProfileOutlined,BranchesOutlined  } from '@ant-design/icons';
import { Button, Col, Layout, Menu, Row } from 'antd';
import { useState } from 'react';


function handleLogOut(){
  var token = document.getElementsByName("csrf-token")[0].content;
  fetch('/users/sign_out', { method: 'DELETE', headers: {'X-CSRF-Token':token}})
    .then((res) => {console.log(res); window.location.assign('/')});
}

const NavBar = (props) => {
  const [current, setCurrent] = useState('dashboard');
  const items = [
    {
      label: <a href='/admin/dashboard'>Admin Dashboard</a>,
      key: 'dashboard',
      icon: <DashboardOutlined />,
      children: [
        {
          type:'button',
          icon: <AppstoreOutlined />,
          label: <a href='/admin/dashboard'>Main Dashboard</a>,
        },
        {
          type:'group',
          icon: <SettingOutlined />,
          label: "App Settings",
          children: [
            {
              type:'button',
              icon: <TeamOutlined />,
              label: <a href='/admin/clients'>Clients</a>,
            },
            {
              type:'button',
              icon: <ShoppingCartOutlined />,
              label: <a href='/admin/vessels'>Vessels</a>,
            },
            {
              type:'button',
              icon: <NumberOutlined />,
              label: <a href='/admin/parcels'>Parcels</a>,
            },
            {
              type:'button',
              icon: <ProfileOutlined />,
              label: <a href='/admin/commodities'>Commodities</a>,
            },
            {
              type:'button',
              icon: <ProfileOutlined />,
              label: <a href='/admin/destinations'>Destinations</a>,
            },
            {
              type:'button',
              icon: <BranchesOutlined />,
              label: <a href='/admin/zones'>Zones</a>,
            },
          ]
        }
      ],
    },
    {
      label: 'SampleShip',
      key: 'sampleship',
      icon: <AppstoreOutlined />,
      children: [
        {
          type:'button',
          label: <a href='https://sampleship.com'> Visit App</a>,
        }
      ],
    },
    {
      label: 'Hyundai/Doosan',
      key: 'hyundai',
      icon: <AppstoreOutlined />,
      children: [
          {
            type:'button',
            label: <a href='https://doosan.sampleship.com'> Visit App</a>,
          },
        ],
    },
    {
      label: 'Volvo',
      key: 'volvo',
      icon: <AppstoreOutlined />,
      children: [
          {
            type:'button',
            label: <a href='https://volvo.sampleship.com'> Visit App</a>,
          },
        ],
    },
    {
      label: props.user.email,
      key: 'user',
      icon: <UserOutlined />,
      children: [
        {
          
          type: 'button',
          label: <a onClick={handleLogOut}> Log out</a>,
        },
      ],
    }
  ];
  
  const onClick = (e) => {
    setCurrent(e.key);
  };
  useEffect(() => {
   if (window.location.href.includes("sampleship")){
    setCurrent("sampleship")
   }
  }, []);
  return (
    <Layout>
        <Row>
            <Col span={24}>
                <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
            </Col>
        </Row>
    </Layout>
    )
};
export default NavBar;