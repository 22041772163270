import React, { useEffect,useState } from 'react'
import { Button, Col, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ClientsTable from '../../components/clientsTable';
import { Typography } from 'antd';
import Notification from '../../components/notification';

const { Title } = Typography;
const Clients = (props) => {
  console.log(props)
  const [success, setSuccess] = useState(false)
  useEffect(() => {
    // if (window.location.href.includes("success")){
    //   setTimeout(() => {
        
    //   },3000) 
    // }
  },[])
  return (
  <>
   <Row>
    <Col span={21}>
      <Title level={3}>Clients Overview</Title>
    </Col>
    <Col span={3}>
      <Button icon={<PlusOutlined />} style={{marginTop: 20}} href='/admin/clients/new' type="primary"level={3}>Create Client</Button>
    </Col>
    </Row>
  <Row>
    <Col push={4} span={16}>
      <ClientsTable data={props.clients} />
      </Col>
  </Row>
  <Row>
    <Col span={4} push={4}>
     {success ? <Notification message={"Client Successfully Created"} messageType='success' icon='success' />:null}
    </Col>
  </Row>
  </>
  )
};
export default Clients;