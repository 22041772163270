import React, { useEffect,useState } from 'react'
import { Button, Col, Divider, Row } from 'antd';
import { PlusOutlined,PoweroffOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import Notification from '../../components/notification';
import ParcelsTable from '../../components/parcelsTable';

const { Title } = Typography;
const ParcelsIndex = (props) => {
  const [success, setSuccess] = useState(false)
  useEffect(() => {
   
  },[])
  return (
  <>
   <Row>
    <Col span={18}>
      <Title level={3}>Parcels Overview</Title>
    </Col>
    <Col span={3}>
      <Button icon={<PoweroffOutlined />} style={{marginTop: 20}} href='/admin/parcels-bulk-activation' type="primary"level={3}>Activate Parcels</Button>
    </Col>
    <Col span={3}>
      <Button icon={<PlusOutlined />} style={{marginTop: 20}} href='/admin/parcels/new' type="primary"level={3}>Create Parcels</Button>
    </Col>
    </Row>
  <Row>
    <Col push={4} span={16}>
      <ParcelsTable data={props.parcels} />
      </Col>
  </Row>
  <Row>
    <Col span={4} push={4}>
     {/* {success ? <Notification message={"Client Successfully Created"} messageType='success' icon='success' />:null} */}
    </Col>
  </Row>
  </>
  )
};
export default ParcelsIndex;