import { Badge, Descriptions,Row,Col, Tooltip } from 'antd';
import React from 'react'
import { Typography,Button,Space } from 'antd';
const { Title } = Typography;
import { PoweroffOutlined, EyeOutlined,EditOutlined,CloudDownloadOutlined } from '@ant-design/icons';
const ShowDestination = (props) => {
    console.log(props)
return (
<>
<Row>
    <Col span={20} style={{marginTop:20}}>
    <Descriptions title="Destination Details" layout="vertical" bordered>
        <Descriptions.Item label="Destination ID">{props.destination.id}</Descriptions.Item>
        <Descriptions.Item span={2} label="Destination Name">{props.destination.name}</Descriptions.Item>
        <Descriptions.Item span={3} label="Destination Details">{JSON.stringify(props.destination.address)}</Descriptions.Item>
    </Descriptions>
    </Col>
  </Row>
  <Row>
    <Col span={24} style={{marginTop:30}}>
        <Button icon={<EditOutlined />} type="primary" href={`/admin/destinations/${props.destination.id}/edit`}>Edit Destination Details</Button>
    </Col>
  </Row>
  </>
);
}
export default ShowDestination;