import React, { useEffect, useState } from 'react'
import { Button, Col, Row,Form,Input,Select, Checkbox} from 'antd';
import { PlusOutlined,MinusCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

const { Title } = Typography;
const EditVessels = (props) => {
  const [nofitications, setNotifications] = useState(false)
  useEffect(() => {
    console.log("LOADED1")
  },[])

  const onFinish = (values) => {
    var token = document.getElementsByName("csrf-token")[0].content;
    fetch(`/admin/vessels/${props.vessel.id}.json`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      credentials: "same-origin",
      body:JSON.stringify(values)
  })
  .then(res => {
    window.location.assign(res.url)
  })
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 4,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 20,
      },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 20,
        offset: 4,
      },
    },
  };
  return (
  <>
   <Row>
    <Col span={21}>
      <Title level={3}>New Client</Title>
    </Col>
    <Col span={3}>
    </Col>
    </Row>
  <Row>
    <Col push={4} span={16}>
              <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={props.vessel}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
              <Form.Item name="name" label="Vessel Name">
                <Input placeholder="..."/>
              </Form.Item>
              <Form.Item  name="imo"  label="Vessel IMO">
                <Input type='number' placeholder="..."/>
              </Form.Item>
              <Form.Item name="active" label="Vessel Active" valuePropName="checked">
                <Checkbox />
              </Form.Item>
              <Form.Item name="client_id" label="Client">
                   <Select
                    defaultValue="Please select Client"
                    options={props.clients}
                  />
                </Form.Item>
                <Form.Item help='Vessel Can Create Shipments without Parcel Numbers' name="can_create_parcel" label="Create Parcels " valuePropName="checked">
                <Checkbox />
              </Form.Item>
              <Form.Item
              style={{marginTop:60}}
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
      </Col>
  </Row>
  </>
  )
};
export default EditVessels;