import { Badge, Descriptions,Row,Col, Tooltip } from 'antd';
import React from 'react'
import { Typography,Button,Space } from 'antd';
const { Title } = Typography;
import KitTable from '../../components/kitTable';
import { PoweroffOutlined, EyeOutlined,EditOutlined,CloudDownloadOutlined } from '@ant-design/icons';
const ShowCommodity = (props) => {
  console.log(props)
return (
<>
<Row>
    <Col span={20} style={{marginTop:20}}>
    <Descriptions title="Commodity Details" layout="vertical" bordered>
        <Descriptions.Item label="Commodity ID">{props.commodity.id}</Descriptions.Item>
        <Descriptions.Item label="Commodity Name">{props.commodity.name}</Descriptions.Item>
        <Descriptions.Item label="Commodity Display Name"><Tooltip placement='bottom' title={'This is what is displayed on shipment documents.'}>{props.commodity.display_name}</Tooltip></Descriptions.Item>
        <Descriptions.Item label="Commodity Code">{props.commodity.code}</Descriptions.Item>
        <Descriptions.Item label="Created at">{props.commodity.created_at}</Descriptions.Item>
        <Descriptions.Item label="Client Owner">{props.client.name}</Descriptions.Item>
        <Descriptions.Item label="Active">{props.commodity.active.toString()}</Descriptions.Item>
        <Descriptions.Item label="Commodity Routing">{(props.commodity.commodity_routing_active? props.commodity.commodity_routing_active.toString(): <>N/A</> )}</Descriptions.Item>
        <Descriptions.Item label="Commodity Routing">{(props.commodity.commodity_routing_active? props.commodity.destination_id.toString(): <>N/A</> )}</Descriptions.Item>
        <Descriptions.Item span={2} label="Documents">{props.commodity_file_urls.map(file => {return <Button style={{marginLeft:5}} icon={<CloudDownloadOutlined />} href={file.url}>{file.name}</Button>})}</Descriptions.Item>
    </Descriptions>
    </Col>
  </Row>
  <Row>
    <Col span={24} style={{marginTop:30}}>
        <Button icon={<EditOutlined />} type="primary" href={`/admin/commodities/${props.commodity.id}/edit`}>Edit Commodity Details</Button>
    </Col>
  </Row>
  </>
);
}
export default ShowCommodity;