import { Badge, Descriptions,Row,Col,Form,Select, Tag,Checkbox } from 'antd';
import React from 'react'
import { Typography,Button,Space,Switch } from 'antd';
const { Title } = Typography;
import KitTable from '../../components/kitTable';
import { PoweroffOutlined, EyeOutlined,EditOutlined } from '@ant-design/icons';
const ParcelEdit = (props) => {
    const onFinish = (values) => {
        var token = document.getElementsByName("csrf-token")[0].content;
        fetch(`/admin/parcels/${props.parcel.id}.json`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": token
          },
          credentials: "same-origin",
          body:JSON.stringify(values)
      })
      .then(res => {
        window.location.assign(res.url.slice(0, -5))
      })
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      const formItemLayout = {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 4,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 20,
          },
        },
      };
      const formItemLayoutWithOutLabel = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 20,
            offset: 4,
          },
        },
      };
return (
<>
<Row>
    <Title level={3}>Edit Parcel {props.parcel.number}</Title>
</Row>
<Row>
    <Col push={1} span={23} style={{marginTop:20}}>
    <Form
        name="basic"
        layout='inline'
        initialValues={props.inital_form_values}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        >
        <Form.Item valuePropName="checked" name='active' label="Parcel Active">
            <Switch checkedChildren={"Active"} unCheckedChildren={"Inactive"} />
        </Form.Item>
        <Form.Item label="Parcel Type" name="parcel_type" validateStatus='warning' help="Please note this only changes the parcel not the associated kit">
                <Select
                  defaultValue="Please Parcel Kit Type"
                  options={[
                    { value: 'fuel', label: 'Fuel Kit' },
                    { value: 'lubricant', label: 'Lube Kit' },
                    { value: 'water', label: 'Water Kit' },
                  ]}
                />
              </Form.Item>
              <Form.Item name="client_id" label="Client"  validateStatus='warning' help="Please note this only changes the parcel not the associated kit">
                 <Select
                  defaultValue="Please select Client"
                  options={props.clients}
                />
              </Form.Item>
                <Form.Item name="remove_from_kit" label="Remove Parcel From Kit" valuePropName="checked">
                    <Checkbox />
                </Form.Item>
        <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
        </Form>
        </Col>
        </Row>
    <Row>
    <Col push={1} span={23} style={{marginTop:30}}>
    <Descriptions title="Parcel Details" layout="vertical" bordered>
        <Descriptions.Item label="Parcel Number">{props.parcel.number}</Descriptions.Item>
        <Descriptions.Item label="Client">{props.client ? props.client.name : <Badge status="error" text={"N/A"} />}</Descriptions.Item>
        <Descriptions.Item label="System">{props.client ? props.client.domain :  <Badge status="error" text={"N/A"} />}</Descriptions.Item>
        <Descriptions.Item label="Status">
        <Badge status="processing" text={<Tag color={'processing'}>Awaiting Shipment</Tag>} />
        </Descriptions.Item>
        <Descriptions.Item label="Kit Size">{props.kit.length}</Descriptions.Item>
        <Descriptions.Item label="Parcel Type">{props.parcel.parcel_type ? props.parcel.parcel_type :  <Badge status="error" text={"N/A"} />}</Descriptions.Item>
        <Descriptions.Item label="Activated at">{props.parcel.activated_on}</Descriptions.Item>
        <Descriptions.Item label="Expires at">{props.parcel.expires_at}</Descriptions.Item>
        <Descriptions.Item label="Created at">{props.parcel.created_at}</Descriptions.Item>
        <Descriptions.Item label="Actions">
        <Space size='middle'>
            {props.parcel.client_id ? <Button icon={<EyeOutlined />} type="primary" href={`/admin/parcels/${props.parcel.id}`}>View Parcel Details</Button>:null}
            <Button disabled icon={<EyeOutlined />} type="primary" href={`/admin/parcels/${props.parcel.id}`}>View Parcel Shipment</Button>
            </Space>
        </Descriptions.Item>
    </Descriptions>
    </Col>
  </Row>
  <Row>
    {/* <Col span={24}>
        <Title level={5}>Kit Details</Title>
        <KitTable kit={props.kit} />
    </Col> */}
  </Row>
  </>
);
}
export default ParcelEdit;