import React, { useEffect, useState } from 'react'
import { Button, Col, Row,Form,Input,Select, Checkbox,Tooltip} from 'antd';
import { PlusOutlined,MinusCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

const { Title } = Typography;
const NewClient = (props) => {
  const [nofitications, setNotifications] = useState(false)
  const [courierAccounts, setCourierAccounts] = useState(false)
  useEffect(() => {
    console.log("LOADED1")
  },[])

  const onFinish = (values) => {
    var token = document.getElementsByName("csrf-token")[0].content;
    var client = {name: values.name, domain: values.domain, support_email:values.support_email, notifications:values.notifications, notification_emails: values.notification_emails, active: values.active, courier_accounts: [{account_key: values.account_key, account_number: values.account_number, account_type: values.account_type, account_origin: values.account_origin, override_low_cost_courier: values.override_low_cost_courier, account_owner_id: values.account_owner_id}]};
    fetch("/admin/clients.json", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      credentials: "same-origin",
      body:JSON.stringify(client)
  })
  .then(res => {
    window.location.assign('/admin/clients')
  })
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 16,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 20,
      },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 20,
        offset: 4,
      },
    },
  };
  return (
  <>
   <Row>
    <Col span={21}>
      <Title level={3}>New Client</Title>
    </Col>
    <Col span={3}>
    </Col>
    </Row>
  <Row>
    <Col push={4} span={16}>
              <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
              <Form.Item name="name" label="Client Name">
                <Input placeholder="..."/>
              </Form.Item>
              <Form.Item  name="support_email"  label="Support Email Address">
                <Input placeholder="..."/>
              </Form.Item>
              <Form.Item name="domain" label="Domain">
                 <Select
                  defaultValue="Please select system for Client"
                  options={[
                    { value: 'sampleship.com', label: 'SampleShip (Main)' },
                    { value: 'volvo.sampleship.com', label: 'Volvo' },
                    { value: 'hyundai.sampleship.com', label: 'Hyundai' },
                    { value: 'develon.sampleship.com', label: 'Develon/Doosan' }
                  ]}
                />
              </Form.Item>
              <Form.Item name="active" label="Client Active" valuePropName="checked">
                <Checkbox />
              </Form.Item>
              <Form.Item onClick={(e) => {setNotifications(e.target.checked)}} name="notifications" label="Shipment Email Notifications" valuePropName="checked">
                <Checkbox />
              </Form.Item>
              <Form.Item name='courier_account' onClick={(e) => {setCourierAccounts(e.target.checked)}} label="Courier Account Number">
                <Checkbox />
              </Form.Item>
              {/* <Form.Item label="Kit Type">
                <Select
                  defaultValue="Please select Kit Type"
                  onChange={handleKitTypeChange}
                  options={[
                    { value: 'fuel', label: 'Fuel Kit' },
                    { value: 'lubricant', label: 'Lube Kit' },
                    { value: 'water', label: 'Water Kit' },
                  ]}
                />
              </Form.Item> */}
        {nofitications ?
        
        <Form.Item>
        <Form.List name="notification_emails">
  {(fields, { add, remove }) => (
    <>
      {fields.map((field, index) => (
        <Form.Item
          {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
          label={index === 0 ? 'Email' : ''}
          required={false}
          key={field.key}
        >
          <Form.Item
            {...field}
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input email in this field.",
              },
            ]}
            noStyle
          >
            <Input
              placeholder="Email address..."
              style={{
                width: '60%',
              }}
            />
          </Form.Item>
          {fields.length > 1 ? (
            <MinusCircleOutlined
              className="dynamic-delete-button"
              onClick={() => remove(field.name)}
            />
          ) : null}
        </Form.Item>
      ))}
      <Form.Item>
        <Button
          type="dashed"
          onClick={() => add()}
         
          icon={<PlusOutlined />}
        >
          Add field
        </Button>
      </Form.Item>
    </>
  )}
</Form.List>
</Form.Item>
        
        : null}
           
        {courierAccounts ?
        <>
        <Form.Item name="account_key" label="Courier">
              <Select
                  defaultValue="Please select a Courier"
                  options={[{label: 'DHL', value: 'dhl'}]}
                />
              </Form.Item>
          <Form.Item name="account_number" label="Account Number">
                <Input placeholder="..."/>
              </Form.Item>
              <Form.Item name="account_type" label="Account Type">
              <Select
                  defaultValue="Please select Account Type"
                  options={[{label: 'Export', value: 'export'},{label: 'Import', value: 'import'},{label: 'Third Country', value: 'thirdcountry'}]}
                />
              </Form.Item>
            <Form.Item name='account_origin' label='Account Origin'>
              <Select
                  showSearch
                  defaultValue="Please select Country"
                  options={props.countries}
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                  
                />
            </Form.Item>
            <Form.Item name='override_low_cost_courier' label="Override Lowest Cost Courier" valuePropName="checked">
                <Tooltip title="This should only be selected if this courier account should be used for all shipments for this client."><Checkbox /></Tooltip>
              </Form.Item>
              <Form.Item name='account_owner_id' label="Courier Account Owner" >
              <Select
                   defaultValue="Please select Account Owner"
                   options={props.clients}
                   filterOption={(input, option) => (option?.label ?? '').includes(input)}
                     filterSort={(optionA, optionB) =>
                     (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                     }
                />
              </Form.Item>
          </>
        : null}
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
                style={{marginTop:10}}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
      </Col>
  </Row>
  </>
  )
};
export default NewClient;