import React, { useEffect,useState } from "react"
import { Row, Typography,Col, Input,Form,Button,Select, Space } from 'antd';
import AdminShipmentsTable from "../components/adminShipmentsTable";
import { ReloadOutlined,SearchOutlined } from '@ant-design/icons';
import Search from "antd/es/input/Search";
const { Title } = Typography;

const AdminIndex = (props) => {
  const [load, setLoad] = useState(true)
  const [shipments, setShipments]= useState()
  const [ownerPlaceholderText, setOwnerPlaceholderText] = useState('Search Vessel Name...')
  const [owner, setOwner] = useState('vessel')
  const [client, setClient] = useState('Search by Client')
  const params= new URLSearchParams(window.location.search);
  const fetchShipments = () => {
    fetch(`/shipments_overview?page=${params[1]}`).then(res => res.json()).then(shipments => {setShipments(shipments); setLoad(false)})
  }
  const onSearch = (value,param) => {
    console.log(value)
    setLoad(true)
    if (param === 'awb'){
      fetch(`/search?awb_number=${value}`).then(res => {
       return res.json()}).then(shipments => {console.log(shipments);setShipments(shipments); setLoad(false)})
    } else if (param === 'parcel'){
      fetch(`/search?parcel_number=${value}`).then(res => {
       return res.json()}).then(shipments => {console.log(shipments);setShipments(shipments); setLoad(false)})
    } else if (param === 'owner'){
      fetch(`/search?${owner}=${value}`).then(res => {
       return res.json()}).then(shipments => {console.log(shipments);setShipments(shipments); setLoad(false)})
    }
  }
  useEffect(() => {
    console.log(owner)
    fetchShipments()
    if (owner === 'vessel'){
      setOwnerPlaceholderText('Search Vessel Name...')
    } else if (owner === 'imo' ){
      setOwnerPlaceholderText('Search Vessel IMO...')
    } else if (owner === 'dealer'){
      setOwnerPlaceholderText('Search Dealer Name...')
    } else {
      setOwnerPlaceholderText('Search Dealer ID...')
    }
  },[owner])
  console.log(client)
    return (
      <div>
      <Row>
        <Col span={24}>
          <Title style={{margin:30}} level={3}>SampleShip Open Shipments Overview</Title>
        </Col>
        </Row>
      <Row>
        <Col span={24}>
          <Form layout="inline">
          <Col span={1}>
            <Form.Item>
              <Button onClick={() => {setLoad(true);fetchShipments()}} icon={<ReloadOutlined />}></Button>
            </Form.Item>
            </Col>
            <Col span={4}>
            <Form.Item>
              <Search allowClear onSearch={(value) => {onSearch(value,'awb')}} placeholder="Search AWB Number..." /> </Form.Item>
            </Col>
            <Col span={4}>
            <Form.Item>
              <Search allowClear onSearch={(value) => {onSearch(value,'parcel')}} placeholder="Search Parcel Number..." /> </Form.Item>
            </Col>
            <Col span={6}>
            <Form.Item>
              <Search addonBefore={<Select value={owner} onChange={setOwner} defaultValue={{label: 'Vessel Name', value:'vessel'}} options={[{label: 'Vessel Name', value: 'vessel'}, {label: 'Vessel IMO', value: "imo"}, {label: 'Dealer Name', value: 'dealer', disabled:true}, {label: 'Dealer ID', value: 'dealerId', disabled:true}]} />} allowClear onSearch={(value) => {onSearch(value,'owner')}} placeholder={ownerPlaceholderText} /> </Form.Item>
            </Col>
            {/* <Col span={4}>
            <Form.Item>
              <Space><Select onChange={setClient} style={{minWidth:200}} value={client} span={4} defaultValue={'Search Client'} options={props.clients} /><Button onClick={() => {onSearch(client,'client')}} style={{margin:-8}} icon={<SearchOutlined />}/></Space>
            </Form.Item>
           </Col> */}
          </Form>
          </Col>
      </Row>
      <Row style={{marginTop:20}}>
        <Col span={24}>
        <AdminShipmentsTable fetchShipments={fetchShipments} params={params} load={load} shipments={shipments} />
        </Col>
      </Row>
      </div>
     );
}

export default AdminIndex
