import React, { useEffect, useState } from 'react'
import { Button, Col, Row,Form,Input,Select, Checkbox,Radio, Divider,Descriptions,Switch} from 'antd';
import { PlusOutlined,MinusCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import KitTableCompact from '../../components/kitTableCompact';

const { Title } = Typography;
const ActivateParcel = (props) => {
  console.log(props)
  const [form] = Form.useForm();
  const generic = Form.useWatch('generic', form);
  const use = Form.useWatch('use', form);
  useEffect(() => {
  },[])

  const onChange = (e) => {
    setValue(e.target.value);
  };
  const onFinish = (values) => {
    var token = document.getElementsByName("csrf-token")[0].content;
    fetch("/admin/activate_parcels", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      credentials: "same-origin",
      body:JSON.stringify(values)
  })
  .then(res => {
    console.log(res)
    window.location.assign('/admin/parcels')
  })
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 4,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 20,
      },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 20,
        offset: 4,
      },
    },
  };
  return (
  <>
   <Row>
    <Col span={21}>
      <Title level={3}>Activate Parcels</Title>
    </Col>
    <Col span={3}>
    </Col>
    </Row>
  <Row>
    
    <Col push={4} span={16}>
              <Form
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={props.kit}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
              <Form.Item name={"start_range"} label="Start Range">
                <Input placeholder="..."/>
              </Form.Item>
              <Form.Item  name="end_range"  label="End Range">
                <Input placeholder="..."/>
              </Form.Item>
              <Form.Item required name="client" label="Client">
                 <Select
                  defaultValue="Please select Client"
                  options={props.clients}
                />
              </Form.Item>
               <Form.Item name='use' label="Kit will be used for">
                <Radio.Group><Radio value={'vessel'}>Vessel</Radio> <Radio value={'dealer'}>Dealer</Radio></Radio.Group>
              </Form.Item>
              <Form.Item name='generic' label="Kit Parcels Active">
                <Switch  checkedChildren={"Generic"} unCheckedChildren={"Not Generic"} />
             </Form.Item>
             {generic ? null
             :
             use === 'vessel' ?  <>
               <Form.Item label="Vessel" name="vessel_id">
                <Select
                  defaultValue="Please select Vessel"
                  options={props.vessels}
                />
              </Form.Item>
             </> 
             :  
             <>
              <Form.Item label='Dealer Name' name='vessel_name'><Input placeholder='Dealer Name...'  /></Form.Item>
              </>
            
             }
              {/* <Form.Item onClick={(e) => {setNotifications(e.target.checked)}} name="notifications" label="Shipment Email" valuePropName="checked">
                <Checkbox />
              </Form.Item> */}
             
              <Form.Item  name="kit_size"  label="Kit Size">
                <Input disabled placeholder="..."/>
              </Form.Item>
              <Form.Item   name="number_of_parcels"  label="Total Number of Parcels">
                <Input disabled placeholder="..."/>
              </Form.Item>
              <Form.Item hidden name="start_id"  label="Total Number of Parcels">
                <Input disabled placeholder="..."/>
              </Form.Item>
              <Form.Item hidden name="end_id"  label="Total Number of Parcels">
                <Input disabled placeholder="..."/>
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
      </Col>
      <Col>
     
      </Col>
  </Row>
  <Row>
   <Divider />
    <Col push={1} span={16}>
    {(props.kit ?  <Descriptions title="Kit Overview" layout="vertical" bordered>
        <Descriptions.Item label="Kit Size">{props.kit.kit_size}</Descriptions.Item>
        <Descriptions.Item span={2} label="Total Number of Parcels">{props.kit.parcels.length}</Descriptions.Item>
        <Descriptions.Item label="Kit Details"> <KitTableCompact kit={props.kit.parcels} /></Descriptions.Item>
    </Descriptions> : null)}
    {/* <Title level={3}>Kit Overview</Title>
   
        <KitTable kit={props.kit.parcels} /> */}
      </Col>
  </Row>
  </>
  )
};
export default ActivateParcel;