import React, { useEffect, useState } from 'react'
import { Button, Col, Row,Form,Input,Select, Checkbox,Tooltip} from 'antd';
import { PlusOutlined,MinusCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

const { Title } = Typography;
const EditClient = (props) => {
  const [notifications, setNotifications] = useState(props.client.notifications)
  const [client, setClient] = useState(props.client)
  const [courierAccounts, setCourierAccounts] = useState((props.client.courier_accounts == {} || props.client.courier_accounts == null ? false :true ))
  console.log(client.courier_accounts)
  useEffect(() => {
   
  },[])

  const onFinish = (values) => {
    var token = document.getElementsByName("csrf-token")[0].content;
    fetch(`/admin/clients/${client.id}.json`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      credentials: "same-origin",
      body:JSON.stringify(values)
  })
  .then(res => {
    window.location.assign('/admin/clients')
  })
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 4,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 20,
      },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 20,
        offset: 4,
      },
    },
  };

  return (
  <>
   <Row>
    <Col span={21}>
      <Title level={3}>Edit Client {client.name}</Title>
    </Col>
    <Col span={3}>
    </Col>
    </Row>
  <Row>
    <Col push={4} span={16}>
              <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={client}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
              <Form.Item name="name" label="Client Name">
                <Input placeholder="..."/>
              </Form.Item>
              <Form.Item  name="support_email"  label="Support Email Address">
                <Input placeholder="..."/>
              </Form.Item>
              <Form.Item name="domain" label="Domain">
                 <Select
                  defaultValue="Please select system for Client"
                  options={[
                    { value: 'sampleship.com', label: 'SampleShip (Main)' },
                    { value: 'volvo.sampleship.com', label: 'Volvo' },
                    { value: 'hyundai.sampleship.com', label: 'Hyundai' },
                    { value: 'doosan.sampleship.com', label: 'Doosan' }
                  ]}
                />
              </Form.Item>
              <Form.Item name="active" label="Client Active" valuePropName="checked">
                <Checkbox />
              </Form.Item>
              <Form.Item onClick={(e) => {setNotifications(e.target.checked)}} name="notifications" label="Shipment Email Notifications" valuePropName="checked">
                <Checkbox />
              </Form.Item>
              <Form.Item name='courier_accounts' onClick={(e) => {setCourierAccounts(e.target.checked)}} label="Courier Account Number" valuePropName="checked">
                <Checkbox />
              </Form.Item>
              {/* <Form.Item label="Kit Type">
                <Select
                  defaultValue="Please select Kit Type"
                  onChange={handleKitTypeChange}
                  options={[
                    { value: 'fuel', label: 'Fuel Kit' },
                    { value: 'lubricant', label: 'Lube Kit' },
                    { value: 'water', label: 'Water Kit' },
                  ]}
                />
              </Form.Item> */}
        {notifications ?
        
        <Form.Item>
<Form.List name="notification_emails">

  {(fields, { add, remove }) => {
   return (
    <>
      {fields.map((field, index) => (
        <Form.Item
          {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
          label={index === 0 ? 'Email' : ''}
          required={false}
          key={field.key}
        >
          <Form.Item
            {...field}
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input email in this field.",
              },
            ]}
            noStyle
          >
            <Input
              placeholder="Email address..."
              style={{
                width: '60%',
              }}
            />
          </Form.Item>
          {fields.length > 1 ? (
            <MinusCircleOutlined
              className="dynamic-delete-button"
              onClick={() => remove(field.name)}
            />
          ) : null}
        </Form.Item>
      ))}
      <Form.Item>
        <Button
          type="dashed"
          onClick={() => add()}
         
          icon={<PlusOutlined />}
        >
          Add field
        </Button>
      </Form.Item>
    </>
  )}}
</Form.List>
</Form.Item>
        : null}
          
          {courierAccounts ?
        <>
        <Form.List name='courier_accounts' >
          
          {(fields, { add, remove }) => (
            
            <>
            {console.log(fields)}
            {fields.map((field, index) => (
              <>
                            {console.log(index)}
               <Form.Item name={[index, "account_key"]} label="Courier">
               <Select
                   defaultValue="Please select a Courier"
                   options={[{label: 'DHL', value: 'dhl'}]}
                 />
               </Form.Item>
           <Form.Item  name={[index, "account_number"]} label="Account Number">
                 <Input placeholder="..."/>
               </Form.Item>
               <Form.Item name={[index, 'account_type']} label="Account Type">
               <Select
                   defaultValue="Please select Account Type"
                   options={[{label: 'Export', value: 'export'},{label: 'Import', value: 'import'},{label: 'Third Country', value: 'thirdcountry'}]}
                 />
               </Form.Item>
             <Form.Item  name={[index, 'account_origin']} label='Account Origin'>
               <Select
                   showSearch
                   defaultValue="Please select Country"
                   options={props.countries}
                   filterOption={(input, option) => (option?.label ?? '').includes(input)}
                     filterSort={(optionA, optionB) =>
                     (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                     }
                   
                 />
             </Form.Item>
             <Form.Item name={[index, 'override_low_cost_courier']} label="Override Lowest Cost Courier" valuePropName="checked">
                 <Tooltip title="This should only be selected if this courier account should be used for all shipments for this client."><Checkbox /></Tooltip>
               </Form.Item>
               <Form.Item name={[index, 'account_owner_id']} label="Courier Account Owner" >
               <Select
                    defaultValue="Please select Account Owner"
                    options={props.clients}
                 />
                 
               </Form.Item>
               {fields.length > 1 ? (
                <Button onClick={() => remove(field.name)} style={{marginBottom:10}} type='primary'><MinusCircleOutlined className="dynamic-delete-button"  /> Remove Courier</Button>) : null}
               </>
            ))}
             <Form.Item>
        <Button
          type="dashed"
          onClick={() => add()}
         
          icon={<PlusOutlined />}
        >
          Add field
        </Button>
      </Form.Item>
            </>
          )}
        
              </Form.List>
          </>
        : null}
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
      </Col>
  </Row>
  </>
  )
};
export default EditClient;