import { Badge, Descriptions,Row,Col, Tooltip } from 'antd';
import React from 'react'
import { Typography,Button,Space } from 'antd';
const { Title } = Typography;
import { PoweroffOutlined, EyeOutlined,EditOutlined,CloudDownloadOutlined } from '@ant-design/icons';
import countryList from 'country-list';
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

const ShowZone = (props) => {
    console.log(props)
return (
<>
<Row>
    <Col span={20} style={{marginTop:20}}>
    <Descriptions title="Zone Details" layout="vertical" bordered>
        <Descriptions.Item label="Zone ID">{props.zone.id}</Descriptions.Item>
        <Descriptions.Item label="Zone Name">{props.zone.name}</Descriptions.Item>
        <Descriptions.Item label="Zone Desintation">{props.destination.name}</Descriptions.Item>
        <Descriptions.Item span={3} label="Zone Countries">{props.zone.countries.map(c => {return (<Space><Typography style={{margin:3}}> {getUnicodeFlagIcon(c)} {countryList.getName(c)} </Typography></Space>)})}</Descriptions.Item>
        <Descriptions.Item label="Zone Client (Owner)">{props.client.name}</Descriptions.Item>
    </Descriptions>
    </Col>
  </Row>
  <Row>
    <Col span={24} style={{marginTop:30}}>
        <Button icon={<EditOutlined />} type="primary" href={`/admin/zones/${props.zone.id}/edit`}>Edit Zone Details</Button>
    </Col>
  </Row>
  </>
);
}
export default ShowZone;