import { InboxOutlined,UploadOutlined } from '@ant-design/icons';
import { Col, message, Upload,Row,Form,Input,Button,Select,Switch } from 'antd';
import Title from 'antd/es/typography/Title';
import React, {useEffect,useState} from 'react'
import {PostalCodeRequired} from '../../utils/shared'
const { Dragger } = Upload;
const NewDestination = (props) => {
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = React.useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false); 
  const [postCodeReq, setPostCodeReq] = useState(true)
  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        },
      );
    },[])

  const returnAddress = (values) => {
    var address = values
    delete address["name"]
    delete address["client_id"]
    var country = values.country
    var countryDetails = {}
    props.countries.forEach(c => {
        if (c.value === country){
            countryDetails = {name: c.details.name, code: c.details.code, region: c.details.region}
        }
    })
    address['country_details'] = countryDetails
    return address
  }
  const onFinish = (values) => {
    var token = document.getElementsByName("csrf-token")[0].content;
    var destination = {name: values.name, client_id: values.client_id, address:returnAddress(values)}
    fetch(`/admin/destinations.json`, {
      method: "POST",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      },
      credentials: "same-origin",
      body:JSON.stringify(destination)
  })
  .then(res => {
    window.location.assign(res.url)
  })
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const uploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };
  console.log(values)
  return (
    <>
    <Row>
      <Col span={21}><Title level={3}>Create Destination</Title></Col>
    </Row>
    <Row>
      <Col push={6} span={8}>  
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        >    
          <Form.Item  rules={[ { required: true }, ]}  name="name"  label="Destination Name">
              <Input placeholder="..."/>
          </Form.Item>
          <Form.Item name="client_id" label="Client">
                 <Select
                  defaultValue="Please select Client"
                  options={props.clients}
                />
              </Form.Item>
          <Form.Item rules={[ { required: true, max: 100 }, ]}  name="company_name"  label="Company Name">
              <Input placeholder="..."/>
          </Form.Item>
          <Form.Item rules={[ { required: true, max: 100 }, ]}  name="contact_name"  label="Contact Name">
              <Input placeholder="..."/>
          </Form.Item>
          <Form.Item rules={[ { required: true, max: 100 }, ]}  name="contact_email"  label="Contact Email">
              <Input placeholder="..."/>
          </Form.Item>
          <Form.Item rules={[ { required: true, max: 100 }, ]}  name="contact_phone"  label="Contact Phone">
              <Input placeholder="..."/>
          </Form.Item>
          <Form.Item rules={[ { required: true, max: 100 }, ]}  name="address_line_one"  label="Address Line One">
              <Input placeholder="..."/>
          </Form.Item>
          <Form.Item rules={[ { max: 100 }, ]}  name="address_line_two"  label="Address Line Two">
              <Input placeholder="..."/>
          </Form.Item>
          <Form.Item rules={[ { max: 100 }, ]}  name="address_line_three"  label="Address Line Three">
              <Input placeholder="..."/>
          </Form.Item>
          <Form.Item rules={[ { max: 50 }, ]}  name="state"  label="County/State">
              <Input placeholder="..."/>
          </Form.Item>
          <Form.Item rules={[ { required: true, max: 50 }, ]}  name="city"  label="Town/City">
              <Input placeholder="..."/>
          </Form.Item>
          <Form.Item name="country" label="Countries">
                 <Select
                 showSearch
                  defaultValue="Please select Country"
                  options={props.countries}
                  onChange={(e => {setPostCodeReq(PostalCodeRequired(e))})}
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                  
                />
              </Form.Item>
          <Form.Item hidden={postCodeReq} rules={[ {required:true,  max: 15 }, ]}  name="postcode"  label="Postal/Zip Code">
              <Input placeholder="..."/>
          </Form.Item>
          
          <Form.Item name="vat_number"  label="VAT Number">
              <Input placeholder="..."/>
          </Form.Item>
          <Form.Item name="tax_id"  label="Tax ID">
              <Input placeholder="..."/>
          </Form.Item>
          <Form.Item name="eori"  label="EORI Number">
              <Input placeholder="..."/>
          </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16, }} >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
          </Form.Item>
      </Form>
      </Col>
    </Row>
    </>

  );
 }
export default NewDestination;